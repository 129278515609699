<template>

  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <div v-if="$route.query.booking">
              <h1 class="custom-header-title">
                Attach Call Log
              </h1>
              <h3 class="custom-header-title">
                Booking {{ bookingDetail.stringID }} - {{ bookingDetail.customerName }}, {{ dateFormatWithTime(bookingDetail.start) }}
                <b-badge
                  :variant="bookingDetail.activityType ? bookingDetail.activityType.color : ''"
                  class="small-badge-button small-badge-button-activity-list"
                  :style="{ '--bg-color': bookingDetail.activityType ? bookingDetail.activityType.color : '' }"
                >
                  <span v-if="bookingDetail.eventID">{{ bookingDetail.eventID ? bookingDetail.eventID.name : '' }}</span>
                  <span v-else>{{ bookingDetail.activityType ? bookingDetail.activityType.typeName : '' }}</span>
                </b-badge>
              </h3>
            </div>
            <div v-if="$route.query.serviceForm">
              <h1 class="custom-header-title">
                Attach Call Log
              </h1>
              <h3 class="custom-header-title">
                {{ serviceFormDetail.stringID }} - {{ serviceFormDetail.customerName }}
              </h3>
            </div>
            <div v-else>
              <h1 class="custom-header-title float-left pr-1 mb-0">
                Create Call Log
              </h1>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <validation-observer
      ref="callLogCreateForm"
      #default="{invalid}"
    >
      <b-form @submit.prevent="submitForm">
        <b-card>
          <b-row>
            <b-col cols="md-6">
              <b-form-group
                label="Customer Name*"
                label-for="h-call-log-customerName"
                label-cols-md="3"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Customer Name"
                  vid="customerName"
                  rules="required"
                >
                  <b-form-input
                    id="h-call-log-customerName"
                    v-model="customerName"
                    placeholder="First name and last name"
                    :state="(errors.length > 0 || customerNameValidation) ? false : null"
                    name="customerName"
                    @input="customerNameValidation == true ? customerNameValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="customerNameValidation"
                    class="text-danger"
                  >
                    {{ customerNameError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-6" />

            <b-col cols="md-6">
              <b-form-group
                label="Mobile Number*"
                label-for="h-call-log-customerContact"
                label-cols-md="3"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Mobile Number"
                  vid="customerContact"
                  rules="required|digits:8"
                >
                  <b-form-input
                    id="h-call-log-customerContact"
                    v-model="customerContact"
                    placeholder="(65) Enter 8-digits number"
                    :state="(errors.length > 0 || customerContactValidation) ? false : null"
                    name="customerContact"
                    @input="customerContactValidation == true ? customerContactValidation = false : null"
                  />
                  <!-- <cleave
                    id="h-call-log-customerContact"
                    v-model="customerContact"
                    class="form-control"
                    :raw="true"
                    :options="phonePrefix"
                    placeholder="Enter 8-digits number"
                    :state="(errors.length > 0 || customerContactValidation) ? false : null"
                    name="customerContact"
                    @input="customerContactValidation == true ? customerContactValidation = false : null"
                  /> -->

                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="customerContactValidation"
                    class="text-danger"
                  >
                    {{ customerContactError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-6" />

            <b-col cols="md-6">
              <validation-provider
                #default="{ errors }"
                name="Type of Call"
                vid="typeOfCall"
                rules="required"
              >
                <b-form-group
                  label="Type of Call*"
                  label-for="h-call-log-typeOfCall"
                  label-cols-md="3"
                  :state="(errors.length > 0 || typeOfCallValidation) ? false : null"
                >
                  <v-select
                    id="h-call-log-typeOfCall"
                    v-model="typeOfCall"
                    label="name"
                    :options="callLogTypes"
                    :reduce="name => name._id"
                    :clearable="false"
                    placeholder="What is the call about?"
                    @input="setNatureOfCalls"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="typeOfCallValidation"
                    class="text-danger"
                  >
                    {{ typeOfCallError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="md-6" />

            <b-col cols="md-6">
              <validation-provider
                #default="{ errors }"
                name="Nature of Call"
                vid="natureOfCall"
                rules="required"
              >
                <b-form-group
                  label="Nature of Call*"
                  label-for="h-call-log-natureOfCall"
                  label-cols-md="3"
                  :state="(errors.length > 0 || natureOfCallValidation) ? false : null"
                >
                  <v-select
                    id="h-call-log-natureOfCall"
                    v-model="natureOfCall"
                    label="title"
                    :options="callNatures"
                    :reduce="title => title.code"
                    :clearable="false"
                    placeholder="What is the specific area of enquiry?"
                    @input="natureOfCallValidation == true ? natureOfCallValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="natureOfCallValidation"
                    class="text-danger"
                  >
                    {{ natureOfCallError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="md-6" />

            <b-col cols="md-9">
              <b-form-group
                label="Details and Remarks"
                label-for="h-call-log-remarks"
                label-cols-md="2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Details and Remarks"
                  vid="remarks"
                  rules=""
                >
                  <b-form-textarea
                    id="h-call-log-remarks"
                    v-model="remarks"
                    placeholder="Details and Remarks"
                    rows="3"
                    :state="(errors.length > 0 || remarksValidation) ? false : null"
                    name="remarks"
                    @input="remarksValidation == true ? remarksValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="remarksValidation"
                    class="text-danger"
                  >
                    {{ remarksError }}
                  </small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="md-3" />

            <b-col cols="md-6">
              <validation-provider
                #default="{ errors }"
                name="Duty Officer"
                vid="dutyOfficer"
                rules="required"
              >
                <b-form-group
                  label="Duty Officer*"
                  label-for="h-call-log-dutyOfficer"
                  label-cols-md="3"
                  :state="(errors.length > 0 || dutyOfficerValidation) ? false : null"
                >
                  <v-select
                    id="h-call-log-dutyOfficer"
                    v-model="dutyOfficer"
                    label="name"
                    :options="dutyOfficers"
                    :reduce="name => name._id"
                    :clearable="false"
                    placeholder="Select an option"
                    @input="dutyOfficerValidation == true ? dutyOfficerValidation = false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <small
                    v-if="dutyOfficerValidation"
                    class="text-danger"
                  >
                    {{ dutyOfficerError }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col cols="md-6" />
          </b-row>
        </b-card>
        <b-nav class="wrap-border save-nav">
          <li
            class="nav-item ml-auto"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="flat-primary"
              :to="{ name: 'appointment-call-logs-index' }"
            >
              Cancel
            </b-button>
          </li>
          <li
            class="nav-item mr-1"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="success"
              :disabled="invalid"
            >
              <feather-icon
                icon="SaveIcon"
                class="mr-50"
              />
              <span class="align-middle">Save Call Log</span>
            </b-button>
          </li>
        </b-nav>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BFormTextarea, BForm, BButton, BNav, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { required, digits } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import Cleave from 'vue-cleave-component'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BButton,
    BNav,
    BBadge,

    vSelect,
    // Cleave,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      customerName: '',
      customerContact: '',
      typeOfCall: '',
      natureOfCall: '',
      remarks: '',
      dutyOfficer: '',
      booking: '',
      serviceForm: '',
      bookingDetail: {},
      serviceFormDetail: {},
      customerNameError: 'Valid Customer Name is required',
      customerNameValidation: false,
      customerContactError: 'Valid customer contact is required',
      customerContactValidation: false,
      typeOfCallError: 'Valid Call Type is required',
      typeOfCallValidation: false,
      natureOfCallError: 'Valid Nature of Call is required',
      natureOfCallValidation: false,
      remarksError: 'Valid remarks is required',
      remarksValidation: false,
      dutyOfficerError: 'Valid Duty Officer is required',
      dutyOfficerValidation: false,
      callLogTypes: [],
      callNatures: [],
      dutyOfficers: [],
      // phonePrefix: {
      //   prefix: '(65)',
      //   blocks: [4, 4, 4],
      //   uppercase: true,
      // },

      // validation rules
      required,
      digits,
    }
  },
  beforeMount() {
    this.$http.get('appointment/call-logs/respond-with/extra-fields')
      .then(response => {
        this.callLogTypes = response.data.callLogTypes ?? []

        // this.typeOfCall = this.callLogTypes[0]._id ?? ''
        this.dutyOfficers = response.data.dutyOfficers ?? []

        // this.dutyOfficer = this.dutyOfficers[0]._id ?? ''
        // this.setNatureOfCalls()
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })

    if (this.$route.query.booking) {
      this.booking = this.$route.query.booking
      this.$http.get(`appointment/bookings/${this.$route.query.booking}`)
        .then(response => {
          this.bookingDetail = response.data || {}
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    if (this.$route.query.serviceForm) {
      this.serviceForm = this.$route.query.serviceForm
      this.$http.get(`operation/service-forms/${this.$route.query.serviceForm}/show`)
        .then(response => {
          this.serviceFormDetail = response.data.data || {}
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
  },
  methods: {
    setNatureOfCalls() {
      // eslint-disable-next-line no-unused-expressions
      this.typeOfCallValidation === true ? this.typeOfCallValidation = false : null

      this.callNatures = []

      const log = this.callLogTypes.find(o => o._id === this.typeOfCall)
      if (log) {
        log.callNatures.forEach(element => {
          const obj = {}
          obj.title = element
          obj.code = element
          this.callNatures.push(obj)
        })

        this.natureOfCall = this.callNatures[0].code ?? ''
      }
    },
    submitForm() {
      this.$refs.callLogCreateForm.validate().then(success => {
        if (success) {
          const formData = new FormData()

          formData.append('customerName', this.customerName)
          formData.append('customerContact', this.customerContact)
          formData.append('typeOfCall', this.typeOfCall)
          formData.append('natureOfCall', this.natureOfCall)
          formData.append('remarks', this.remarks)
          formData.append('booking', this.booking)
          formData.append('serviceForm', this.serviceForm)
          formData.append('dutyOfficer', this.dutyOfficer)

          this.$http.post('appointment/call-logs/store', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(response => {
              if (response.data.data) {
                this.$swal({
                  title: 'Call Log Created',
                  html: response.data.message || '',
                  // eslint-disable-next-line global-require
                  imageUrl: require('@/assets/images/icons/save.png'),
                  imageWidth: 80,
                  imageHeight: 80,
                  imageAlt: 'Custom Icon',
                  showCancelButton: true,
                  cancelButtonText: 'View Entry',
                  allowOutsideClick: false,
                  confirmButtonText: 'Close and Return',
                  customClass: {
                    confirmButton: 'btn btn-primary mr-1',
                    cancelButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
                  .then(result => {
                    if (result.value) {
                      if (this.$route.query.booking) {
                        this.$router.push({ name: 'appointment-bookings-show', params: { id: this.$route.query.booking } })
                      } else if (this.$route.query.serviceForm) {
                        this.$router.push({ name: 'operation-service-forms-show', params: { id: this.$route.query.serviceForm } })
                      } else {
                        this.$router.push({ name: 'appointment-call-logs-index' })
                      }
                    } else {
                      this.$router.push({ name: 'appointment-call-logs-show', params: { id: response.data.data._id } })
                    }
                  })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: response.data.message || '',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'customerName') {
                    this.customerNameError = validationError.msg
                    this.customerNameValidation = true
                  } else if (validationError.param === 'customerContact') {
                    this.customerContactError = validationError.msg
                    this.customerContactValidation = true
                  } else if (validationError.param === 'typeOfCall') {
                    this.typeOfCallError = validationError.msg
                    this.typeOfCallValidation = true
                  } else if (validationError.param === 'natureOfCall') {
                    this.natureOfCallError = validationError.msg
                    this.natureOfCallValidation = true
                  } else if (validationError.param === 'remarks') {
                    this.remarksError = validationError.msg
                    this.remarksValidation = true
                  } else if (validationError.param === 'dutyOfficer') {
                    this.dutyOfficerError = validationError.msg
                    this.dutyOfficerValidation = true
                  }
                })

                const items = document.getElementsByClassName('text-danger')
                window.scrollTo({
                  top: items[0].offsetTop,
                  behavior: 'smooth',
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>
